/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/@splidejs/splide@4.1.4/dist/js/splide.min.js
 * - /npm/macy@2.5.1/dist/macy.min.js
 * - /npm/lightgallery@2.8.2/lightgallery.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
